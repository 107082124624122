<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Home",

  metaInfo: { title: "Inicio" },

  extends: View,

  mixins: [
    LoadSections([
      "mainbanner",
      "hero",
      "frontventanas",
      "frontcerrajeria",
      "frontfontaneria",
      "frontelectricistas",
      "frontpersianascristaleria",
      "frontalbanileria",
      "frontclimatizacion",
      "frontnosotros"
    ])
  ],

  props: {
    id: {
      type: String,
      default: "home"
    }
  }
};
</script>
